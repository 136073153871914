import React from 'react';
import { TrackedLink } from '../..';

import './main-nav.scss';

const MainNav = () => {
  return (
    <nav className="main-nav">
      <TrackedLink type="main-nav__link" to="/features" children="Features" />
      <TrackedLink type="main-nav__link" to="/plus" children="Partie Plus" />
      <TrackedLink type="main-nav__link" to="/leaders" children="Leaders" />
      <TrackedLink type="main-nav__link" rel="noreferrer" to="https://support.partie.com" children="Help &amp; Support" target="_blank" />
    </nav>
  )
}

export default MainNav;
